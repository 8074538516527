<template>
    <Layout>
      <template v-if="this.$route.name == 'add-rep'">
        <PageHeader :title="title1" :items="items"></PageHeader>
      </template>
      <template v-else>
        <PageHeader :title="title2" :items="items"></PageHeader>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body" id="medinext">
              <b-form enctype="multipart/form-data" ref="promotion">
                <div class="row">
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="rep_fname">Rep First name <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="rep_fname"
                        placeholder="Enter First name"
                        v-model="form.fname"
                        :class="{
                          'is-invalid': submitted && $v.form.fname.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.fname.required" class="invalid-feedback">
                            Rep First Name is required.
                        </div>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="rep_lname">Rep Last name <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="rep_lname"
                        placeholder="Enter Last name"
                        v-model="form.lname"
                        :class="{
                          'is-invalid': submitted && $v.form.lname.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.lname.required" class="invalid-feedback">
                            Rep Last Name is required.
                        </div>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="rep_username">Rep User name <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="rep_username"
                        placeholder="Enter User name"
                        v-model="form.username"
                        :class="{
                          'is-invalid': submitted && $v.form.username.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.username.required" class="invalid-feedback">
                            Rep User Name is required.
                        </div>
                    </b-form-group>
                    <b-form-group
                        label="Password"
                        label-for="password-input"
                        description="Enter a strong password"
                        class="col-lg-6 col-md-6 col-sm-12"
                    >
                    <input type="password" name="fake-pass" style="display: none;" autocomplete="off">
                        <b-form-input
                        id="password-input"
                        v-model="form.password"
                        type="password"
                        placeholder="Change password"
                        autocomplete="new-password"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                >
                  <span v-if="this.$route.name == 'add-rep'"
                    >Save Data</span
                  >
                  <span v-else>Update Data</span>
                </b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../../layouts/main";
  import PageHeader from "@/components/page-header";
  import MixinRequest from "../../../../mixins/request";
  import mrapprepMixin from "../../../../mixins/ModuleJs/rep";
  import { required } from "vuelidate/lib/validators";
  
  export default {
    data() {
      return {
        submitted: false,
        title1: "Add Rep",
        title2: "Edit Rep",
        items: [
          {
            text: "List",
            href: "/field-rep-app/rep",
          },
          {
            text: "Data",
          },
        ],
      };
    },
    mixins: [MixinRequest, mrapprepMixin],
    components: {
      Layout,
      PageHeader
    },
    validations: {
      form: {
        fname: { required },
        lname: { required },
        username: { required }
      },
    },
  };
  </script>
  