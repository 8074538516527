var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-rep')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body",attrs:{"id":"medinext"}},[_c('b-form',{ref:"promotion",attrs:{"enctype":"multipart/form-data"}},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('label',{attrs:{"for":"rep_fname"}},[_vm._v("Rep First name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.form.fname.$invalid,
                    },attrs:{"type":"text","id":"rep_fname","placeholder":"Enter First name"},model:{value:(_vm.form.fname),callback:function ($$v) {_vm.$set(_vm.form, "fname", $$v)},expression:"form.fname"}}),(_vm.submitted && !_vm.$v.form.fname.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Rep First Name is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('label',{attrs:{"for":"rep_lname"}},[_vm._v("Rep Last name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.form.lname.$invalid,
                    },attrs:{"type":"text","id":"rep_lname","placeholder":"Enter Last name"},model:{value:(_vm.form.lname),callback:function ($$v) {_vm.$set(_vm.form, "lname", $$v)},expression:"form.lname"}}),(_vm.submitted && !_vm.$v.form.lname.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Rep Last Name is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('label',{attrs:{"for":"rep_username"}},[_vm._v("Rep User name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.form.username.$invalid,
                    },attrs:{"type":"text","id":"rep_username","placeholder":"Enter User name"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),(_vm.submitted && !_vm.$v.form.username.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Rep User Name is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-lg-6 col-md-6 col-sm-12",attrs:{"label":"Password","label-for":"password-input","description":"Enter a strong password"}},[_c('input',{staticStyle:{"display":"none"},attrs:{"type":"password","name":"fake-pass","autocomplete":"off"}}),_c('b-form-input',{attrs:{"id":"password-input","type":"password","placeholder":"Change password","autocomplete":"new-password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-rep')?_c('span',[_vm._v("Save Data")]):_c('span',[_vm._v("Update Data")])])],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }